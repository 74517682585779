export const ROUTES = {
	HOME: "https://leonawong.com",
	PRODUCT: "https://drive.google.com/file/d/1ay-xpZ8C0YyybX1dECduLkCJKHFfbhpH/view",
	EDUVERSE: "https://drive.google.com/file/d/1LyvmgUvvPXS83AWQ52BOcwVqNVUiB-O1/view",
	HR: "https://docs.google.com/presentation/d/1f4R_5jqDTR0ncJio6WJWQMv6Qd4G0tX8_tKceYGzguk/view",
	HORUS: "https://drive.google.com/file/d/1L8wTXNAflOcB0Rq2IqiYaaZEjGuHnvil/view",
	WHATSAPP: "https://wa.me/85260177696",
	LINKEDIN: "https://linkedin.com/in/leonawong",
	INSTAGRAM: "https://instagram.com/leonawong",
	EMAIL: "mailto:leonawongatwork@gmail.com",
	DESIGN: "https://leonawong.design",
	PHOTOG: "https://leonawong.design/photography/street-photography.pdf",
};
