import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="pb-20 relative">
      © 2024 Leona Wong. All Rights Reserved
    </footer>
  );
};

export default Footer;