import React, { useState } from "react";

interface TooltipProps {
	text: string;
	children: React.ReactNode;
	placement?: "top" | "bottom" | "left" | "right";
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, placement = "top"}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const handleTooltipToggle = () => {
		setShowTooltip((prevState) => !prevState);
	};

	const getTooltipClassName = () => {
		let className = "tooltip ";

		switch (placement) {
			case "top":
				className += "tooltip-top";
				break;
			case "bottom":
				className += "tooltip-bottom";
				break;
			case "left":
				className += "tooltip-left";
				break;
			case "right":
				className += "tooltip-right";
				break;
			default:
				break;
		}

		return className;
	};

	return (
		<div className="relative">
			<div
				className="inline-block"
				onMouseEnter={handleTooltipToggle}
				onMouseLeave={handleTooltipToggle}>
				{children}
			</div>
			{showTooltip && <div className={getTooltipClassName()}>{text}</div>}
		</div>
	);
};

export default Tooltip;
