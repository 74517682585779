import React, { useEffect, useRef } from "react";
import WOW from "wowjs";
import CasesData from "../cases.json";
import { Link } from "react-router-dom";

const ProductCases = () => {
	const productCases = CasesData;
	const casesRef = useRef<Array<HTMLDivElement | null>>([]);

	useEffect(() => {
		new WOW.WOW({
			boxClass: "wow",
			animateClass: "animated",
			offset: 0,
			mobile: true,
			live: true,
		}).init();
	}, []);

	useEffect(() => {
		const animateRows = () => {
			casesRef.current.forEach((caseRef, index) => {
				if (caseRef) {
					const row = Math.floor(index / 4); // Assuming 4 cases per row
					caseRef.setAttribute("data-wow-delay", `${row * 0.5}s`);
				}
			});
		};

		animateRows();
	}, []);

	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-12 px-8">
				{productCases.map((productCase, index) => (
					<div
						key={productCase.id}
						className="product-case relative wow bounceIn"
						ref={(el) => (casesRef.current[index] = el)}>
						<Link
							to={productCase.url}
							target="_blank"
							className="cursor-alias">
							<img
								src={productCase.image}
								alt={productCase.title}
								className="w-full h-auto wow lazyload"
							/>
							<p className="product-title">{productCase.title}</p>
							<p className="product-description">{productCase.description}</p>
						</Link>
					</div>
				))}
			</div>
		</>
	);
};

export default ProductCases;
