import React, { useEffect } from "react";
import Card from "../components/Card";
import WOW from "wowjs";

const Features: React.FC = () => {
	
	useEffect(() => {
		const wow = new WOW.WOW();
		wow.init();
	}, []);

	return (
			<div
				id="features"
				className="container mx-auto row clearfix mt-10 mb-20">
				<h1 className="text-center mb-8">Features</h1>
				<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-10 px-8 text-center justify-center">
					{" "}
					<Card
						icon="feature-icon entrepreneurial wow fadeIn"
						title="Entrepreneurial"
						//description="Entrepreneurial"
					/>
					<Card
						icon="feature-icon leadership wow fadeIn"
						title="Leadership"
					/>
					<Card
						icon="feature-icon product wow fadeIn"
						title="Product Mgmt."
					/>
					<Card
						icon="feature-icon ux wow fadeIn"
						title="UX Design"
					/>
					<Card
						icon="feature-icon blockchain wow fadeIn"
						title="Blockchain"
					/>
					<Card
						icon="feature-icon ai wow fadeIn"
						title="AI"
					/>
				</div>
			</div>
	);
};

export default Features;
