import React, { useRef, useEffect, useState } from 'react';

interface SkillBarProps {
  skill: string;
  percentage: number;
}

const SkillBar: React.FC<SkillBarProps> = ({ skill, percentage }) => {
  const skillRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (skillRef.current) {
      observer.observe(skillRef.current);
    }

    return () => {
      if (skillRef.current) {
        observer.unobserve(skillRef.current);
      }
    };
  }, []);

  return (
    <div className={`skill-bar ${isVisible ? 'visible' : ''}`} ref={skillRef}>
      <div className="skill-name">{skill}</div>
      <div className="bar-container">
        <div className="bar" style={{ width: `${isVisible ? percentage : 0}%` }} />
      </div>
    </div>
  );
};

export default SkillBar;