import React, { useState, useEffect } from 'react';

interface TypewriterProps {
  lines: string[];
}

const Typewriter: React.FC<TypewriterProps> = ({ lines }) => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    let currentText = '';

    const interval = setInterval(() => {
      if (currentIndex < lines[currentLineIndex].length) {
        currentText += lines[currentLineIndex][currentIndex];
        setCurrentText(currentText);
        currentIndex++;
      } else {
        clearInterval(interval);
        if (currentLineIndex < lines.length - 1) {
          setTimeout(() => {
            setCurrentLineIndex(currentLineIndex + 1);
            setCurrentText('');
          }, 1000);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [currentLineIndex, lines]);

  return (
    <div>
      {currentLineIndex > 0 && <div>{lines[currentLineIndex - 1]}</div>}
      <div>{currentText}</div>
    </div>
  );
};

export default Typewriter;