import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCameraRetro,
	faObjectUngroup,
} from "@fortawesome/free-solid-svg-icons";
import { Parallax } from "react-parallax";
import Button from "../components/Button";
import Typewriter from "../components/Typewriter";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { ROUTES } from "../definition";
import Tooltip from "../components/Tooltip";

const Overview: React.FC = () => {
	const lines = ["Hi, I am Leona Wong!", "A Product Architect."];
	const learnmore = "/leonawong_resume_product.pdf";

	useEffect(() => {
		new WOW.WOW({
			boxClass: "wow",
			animateClass: "animated",
			offset: 0,
			mobile: true,
			live: true,
		}).init();
	}, []);

	return (
		<div id="overview">
			<Parallax
				bgImage="./images/leonawong.png"
				strength={500}>
				<div
					id="overview-content"
					className="container px-10 mx-auto row clearfix full-screen">
					<div className="flex flex-col lg:flex-row">
						<div className="w-full lg:w-1/2">
							<div className="flex md:flex-row items-center mb-6">
								<div className="lg:hidden mr-6">
									<img
										className="rounded-full w-20 h-auto sm:w-24"
										src="./images/avatar_leonawong.jpg"
										alt="leonawong"
									/>
								</div>
								<div>
									<h1 className="primary-gradient-text">
										<Typewriter lines={lines} />
									</h1>
								</div>
							</div>
							<p>
								As a tech-savvy innovation leader, I have delivered user-centric
								solutions across industries. Leveraging expertise in emerging
								technologies like AI, blockchain and web3. Spearheaded platforms
								including SpeedFusion Cloud and STEMChain Marketplace, gaining
								rapid adoption by addressing user needs.
							</p>
							<p className="mt-3">
								Through strategic product management and expert UX guidance, I
								optimize solutions to maximize both business goals and user
								experience.
							</p>
							<Link
								to={learnmore}
								target="_blank">
								<Button className="btn btn-primary btn-learn-more mt-8 text-sm md:text-md">
									Learn More
								</Button>
							</Link>
						</div>
					</div>
					<div className="scroll-down-icon">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							className="w-12 h-12">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="m19.5 8.25-7.5 7.5-7.5-7.5"
							/>
						</svg>
					</div>
					<div className="other-sites">
						<div
							className="relative wow bounceIn hover:bounceIn"
							data-wow-delay="0.5s">
							<Link
								to={ROUTES.DESIGN}
								target="_blank">
								<Tooltip
									text="See My Design"
									placement="left">
									<FontAwesomeIcon
										icon={faObjectUngroup}
										className="sites-icon design"
									/>
								</Tooltip>
							</Link>
						</div>
						<div
							className="relative wow bounceIn"
							data-wow-delay="1s">
							<Link
								to={ROUTES.PHOTOG}
								target="_blank">
								<Tooltip
									text="See My Photography"
									placement="left">
									<FontAwesomeIcon
										icon={faCameraRetro}
										className="sites-icon photography"
									/>
								</Tooltip>
							</Link>
						</div>
					</div>
				</div>
			</Parallax>
		</div>
	);
};

export default Overview;
