import React from 'react';
import Timeline from '../components/Timeline';

const Roadmap: React.FC = () => {
  return (
		<div
			id="roadmap"
			className="w-full mt-16 row clearfix text-center">
			<h1 className="mb-4">Roadmap</h1>
			<p className="description mb-10 px-20">Unlocking my career journey through 4 distinct stages</p>
			<Timeline />
		</div>
	);
};

export default Roadmap;