import React from "react";

interface CardProps {
	icon: string;
	title?: string;
	description?: string;
}

const Card: React.FC<CardProps> = ({ icon, title, description }) => {
	return (
		<div className="card p-6 relative wow bounceIn">
			<div className="flex justify-center items-center mb-4">
				<div className={icon}></div>
			</div>
			<p className="text-sm text-center font-semibold">{title}</p>
			<p className="description text-center">{description}</p>
		</div>
	);
};

export default Card;
