import React from "react";


const News: React.FC = () => {
	return (
		<div id="news">
        </div>
	);
};

export default News;
