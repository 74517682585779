import React, { useState } from "react";
import ScrollSpy from "react-scrollspy-navigation";
import SocialIcons from "./SocialIcons";

export default function Header() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	const handleMobileMenuToggle = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	const handleMobileMenuItemClick = () => {
		setMobileMenuOpen(false);
	};

	return (
		<header className="sticky top-0 z-10">
			{/* Google Tag Manager */}
			<script
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-T6P2TC27');
          `,
				}}
			/>
			{/* End Google Tag Manager */}

			<nav
				className="mx-auto flex items-center justify-between py-2 px-6"
				aria-label="Global">
				<div className="flex lg:flex-1">
					<a
						href="/"
						className="-m-1.5 p-1.5">
						<span className="sr-only">leonawong</span>
						<div className="logo"></div>
					</a>
				</div>
				<div className="flex lg:hidden">
					<div
						className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}
						onClick={handleMobileMenuToggle}>
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
					</div>
				</div>
				<div className="hidden lg:flex lg:gap-x-12 text-menu">
					<ScrollSpy
						offsetTop={80}
						activeClass="text-white font-bold">
						<a href="#overview">Overview</a>
						<a href="#features">Features</a>
						<a href="#functions">Functions</a>
						<a href="#roadmap">Roadmap</a>
						<a href="#cases">Cases</a>
					</ScrollSpy>
				</div>
				<div className="hidden space-x-4 lg:flex lg:flex-1 lg:justify-end">
					<SocialIcons />
				</div>
			</nav>
			{mobileMenuOpen && (
				<ScrollSpy offsetTop={320}>
					<div className="mobile-nav lg:hidden">
						<ul>
							<a
								href="#overview"
								onClick={handleMobileMenuItemClick}>
								<li>Overview</li>
							</a>
							<a
								href="#features"
								onClick={handleMobileMenuItemClick}>
								<li>Features</li>
							</a>
							<a
								href="#functions"
								onClick={handleMobileMenuItemClick}>
								<li>Functions</li>
							</a>
							<a
								href="#roadmap"
								onClick={handleMobileMenuItemClick}>
								<li>Roadmap</li>
							</a>
							<a
								href="#cases"
								onClick={handleMobileMenuItemClick}>
								<li>Cases</li>
							</a>
						</ul>
					</div>
				</ScrollSpy>
			)}
		</header>
	);
}
