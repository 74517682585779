import React from 'react';

interface TimelineItemProps {
  //backgroundImage: string;
  year: string;
  title: string;
  description: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  //backgroundImage,
  year,
  title,
  description,
}) => {
  return (
    <div className="tl-item">
      <div
        className="tl-bg"
        //style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="tl-year primary-gradient-text">
        <p>{year}</p>
      </div>
      <div className="tl-content">
        <h3 className="mb-3">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Timeline: React.FC = () => {
  return (
		<section
			id="timeline"
			className="grid grid-cols-2 lg:grid-cols-4 gap-0">
			<TimelineItem
				year="2003-2011"
				title="Graphic Designer"
				description="Began the career as graphic designer and worked in international firms"
			/>
			<TimelineItem
				year="2012-19"
				title="Entrepreneur"
				description="Work on UI/UX design and development services in website & web application"
			/>
			<TimelineItem
				year="2019-2021"
				title="Digital Innovation"
				description="Formed an agile team in a listed company & explored advance technologies"
			/>
			<TimelineItem
				//backgroundImage="https://placeimg.com/800/800/nature"
				year="2022 ..."
				title="Product Architect"
				description="Work in start-ups across multiple industries to build impactful products."
			/>
		</section>
	);
};

export default Timeline;