import React from 'react';
import CountingNumber from '../components/CountingNumber';
import ProductCases from '../components/ProductCases';

const Cases: React.FC = () => {
  return (
		<div className="container mx-auto row clearfix mt-16">
			<div id="counting" className="px-10 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 lg:px-24">
				<CountingNumber
					number={16}
					symbol="+"
					description="Years of Experience"
				/>
				<CountingNumber number={8} description="Products" />
				<CountingNumber number={40} symbol="+" description="UI/UX Designs" />
				<CountingNumber number={30} symbol="+" description="Web Apps" />
			</div>
			<div id="cases">
				<h1 className="text-center mt-4 mb-16">Cases</h1>
				<ProductCases />
			</div>
		</div>
	);
};

export default Cases;