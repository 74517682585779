import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Overview from "./Overview";
import Functions from "./Functions";
import Features from "./Features";
import Roadmap from "./Roadmap";
import Cases from "./Cases";
import News from "./News";
import "../index.css";
import BackToTopBtn from "../components/BackToTop";
import ConnectMe from "../components/ConnectMe";

const Home: React.FC = () => {
	return (
		<div className="bg-black">
			<Header />
			<Overview />
			<Features />
			<Functions />
			<Roadmap />
			<News />
			<Cases />
			<ConnectMe />
			<Footer />
			<BackToTopBtn />
		</div>
	);
};

export default Home;
