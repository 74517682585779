import React, { useEffect, useState, useRef } from 'react';

interface CountingNumberProps {
  number: number;
  description: string;
  symbol?: string;
}

const CountingNumber: React.FC<CountingNumberProps> = ({ number, description, symbol }) => {
  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const numberRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isCounting) {
        setIsCounting(true);
      }
    });

    if (numberRef.current) {
      observer.observe(numberRef.current);
    }

    return () => {
      if (numberRef.current) {
        observer.unobserve(numberRef.current);
      }
    };
  }, [isCounting]);

  useEffect(() => {
    if (isCounting) {
      const timer = setInterval(() => {
        if (count < number) {
          setCount((prevCount) => prevCount + 1);
        } else {
          clearInterval(timer);
          setIsCounting(false);
        }
      }, 50);

      return () => {
        clearInterval(timer);
        setIsCounting(false);
      };
    }
  }, [count, number, isCounting]);

  return (
    <div ref={numberRef} className="counting-number-container">
      <h3 className="counting-number">{count}{symbol}</h3>
      <p className="counting-description">{description}</p>
    </div>
  );
};

export default CountingNumber;