import React from "react";
import SkillBar from "../components/SkillBar";

const Functions: React.FC = () => {
	return (
		<div
			id="functions"
			className="container mx-auto mb-20 px-10 row clearfix">
			<h1 className="text-center mb-10">Functions</h1>
			<div className="grid grid-cols-2 md:grid-cols-3 gap-x-24 gap-y-3 justify-center">
				<SkillBar
					skill="Adobe CS"
					percentage={100}
				/>
				<SkillBar
					skill="Figma"
					percentage={100}
				/>
				<SkillBar
					skill="Wordpress"
					percentage={90}
				/>
				<SkillBar
					skill="ReactJS"
					percentage={80}
				/>
				<SkillBar
					skill="HTML5"
					percentage={80}
				/>
				<SkillBar
					skill="CSS3"
					percentage={85}
				/>
			</div>
		</div>
	);
};

export default Functions;
