import React from "react";
import SocialIcons from './SocialIcons';

const ConnectMe = () => {

	return (
		<>
			<div id="connect-me">
				<h1 className="primary-gradient-text text-center mt-24 mb-2">
					Want to know more details?
				</h1>
				<p className="description text-center mb-6">Let's get connected!</p>
				<div className="flex justify-center mb-20">
					<div className="flex justify-center space-x-6">
						<SocialIcons />
					</div>
				</div>
			</div>
		</>
	);
};

export default ConnectMe;