import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./definition";
import Home from "./views/Home";
import Blog from "./views/Blog";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const GTM_ID = 'GTM-T6P2TC27'; // Replace with your GTM tracking ID

function Redirect ({ url }: { url: string }) {
	useEffect(() => {
		window.location.href = url;
	}, [url]);

	return null;
}

function App() {
	ReactGA.initialize("G-QRFE79PTKF");

	useEffect(() => {
		TagManager.initialize({ gtmId: GTM_ID });
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/blog"
					element={<Blog />}
				/>
				<Route
					path="/product"
					element={<Redirect url={ROUTES.PRODUCT} />}
				/>
				<Route
					path="/product/eduverse"
					element={<Redirect url={ROUTES.EDUVERSE} />}
				/>
				<Route
					path="/product/ai-hr-platform"
					element={<Redirect url={ROUTES.HR} />}
				/>
				<Route
					path="/product/horus-petty-cash"
					element={<Redirect url={ROUTES.HORUS} />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
