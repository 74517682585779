import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLinkedinIn,
	faWhatsapp,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ROUTES } from "../definition";

const SocialIcons = () => {

	return (
		<>
			<Link
				to={ROUTES.WHATSAPP}
				target="_blank"
				rel="noopener noreferrer">
				<FontAwesomeIcon
					icon={faWhatsapp}
					className="social-icons"
				/>
			</Link>
			<Link
				to={ROUTES.LINKEDIN}
				target="_blank"
				rel="noopener noreferrer">
				<FontAwesomeIcon
					icon={faLinkedinIn}
					className="social-icons"
				/>
			</Link>
			<Link
				to={ROUTES.INSTAGRAM}
				target="_blank"
				rel="noopener noreferrer">
				<FontAwesomeIcon
					icon={faInstagram}
					className="social-icons"
				/>
			</Link>
			<Link
				to={ROUTES.EMAIL}
				target="_blank"
				rel="noopener noreferrer">
				<FontAwesomeIcon
					icon={faEnvelope}
					className="social-icons"
				/>
			</Link>
		</>
	);
};

export default SocialIcons;
