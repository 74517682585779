import React from 'react';

interface ButtonProps {
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
  }
  
  export default function Button({ className, onClick, children }: ButtonProps) {
    return (
      <button
        className={`button ${className}`}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }